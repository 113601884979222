
require("./../../node_modules/@module-federation/nextjs-mf/src/include-defaults");
import { GetServerSideProps } from 'next';

import AuthoredPageRoutes from '@/components/authored-page-routes/authored-page-routes';
import { PLP_PAGE_TYPE } from '@/data/constants';
import { PreRenderProps } from '@/pages/_app';
import { getPlpViewDefinition } from '@/ssr/view-definition-plp-page-query';
import { fetchDataAndHandleErrors } from '@/utils/ssr-utils';

export const getServerSideProps: GetServerSideProps<PreRenderProps> = async (context) => {
  const data = await fetchDataAndHandleErrors(context, getPlpViewDefinition, PLP_PAGE_TYPE.BRAND);
  return { props: data };
};

const BrandPlpRoutes = (props: any) => <AuthoredPageRoutes pageType={PLP_PAGE_TYPE.BRAND} {...props} />;

export default BrandPlpRoutes;
